var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return " <section class=\"network name-"
    + alias2(__default(require("../js/libs/handlebars/slugify.js")).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"slugify","hash":{},"data":data}))
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.serverOptions : depth0)) != null ? stack1.NETWORK : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.connected : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.status : depth0)) != null ? stack1.secure : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"network-"
    + alias2(alias3((depth0 != null ? depth0.uuid : depth0), depth0))
    + "\" data-uuid=\""
    + alias2(alias3((depth0 != null ? depth0.uuid : depth0), depth0))
    + "\" data-nick=\""
    + alias2(alias3((depth0 != null ? depth0.nick : depth0), depth0))
    + "\" data-options=\""
    + alias2(__default(require("../js/libs/handlebars/tojson.js")).call(alias1,(depth0 != null ? depth0.serverOptions : depth0),{"name":"tojson","hash":{},"data":data}))
    + "\" role=\"region\"> "
    + ((stack1 = container.invokePartial(require("./chan.tpl"),depth0,{"name":"chan","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " </section> ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "network-"
    + container.escapeExpression(__default(require("../js/libs/handlebars/slugify.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.serverOptions : depth0)) != null ? stack1.NETWORK : stack1),{"name":"slugify","hash":{},"data":data}));
},"4":function(container,depth0,helpers,partials,data) {
    return "not-connected";
},"6":function(container,depth0,helpers,partials,data) {
    return "not-secure";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.networks : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});