var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " self";
},"3":function(container,depth0,helpers,partials,data) {
    return " highlight";
},"5":function(container,depth0,helpers,partials,data) {
    return " <span>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "<div class=\"msg msg-"
    + alias2(__default(require("../js/libs/handlebars/slugify.js")).call(alias1,(depth0 != null ? depth0.command : depth0),{"name":"slugify","hash":{},"data":data}))
    + " "
    + alias2(alias3((depth0 != null ? depth0.type : depth0), depth0))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.self : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.highlight : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"msg-"
    + alias2(alias3((depth0 != null ? depth0.id : depth0), depth0))
    + "\" data-time=\""
    + alias2(alias3((depth0 != null ? depth0.time : depth0), depth0))
    + "\"><span class=\"time tooltipped tooltipped-e\" aria-label=\""
    + alias2(__default(require("../js/libs/handlebars/localetime.js")).call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"localetime","hash":{},"data":data}))
    + "\"> "
    + alias2(__default(require("../js/libs/handlebars/tz.js")).call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"tz","hash":{},"data":data}))
    + " </span><span class=\"from\">["
    + alias2(alias3((depth0 != null ? depth0.command : depth0), depth0))
    + "]</span> <span class=\"content\"> "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.params : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </span></div>";
},"useData":true});