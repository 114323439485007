var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("../user_name.tpl"),(depth0 != null ? depth0.from : depth0),{"name":"../user_name","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " has changed the topic to: ";
},"3":function(container,depth0,helpers,partials,data) {
    return " The topic is: ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.nick : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " <span class=\"new-topic\">"
    + ((stack1 = __default(require("../../js/libs/handlebars/parse.js")).call(alias1,(depth0 != null ? depth0.text : depth0),{"name":"parse","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>";
},"usePartial":true,"useData":true});