var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " <button class=\"toggle-button toggle-preview "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.shown : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-url=\""
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.link : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"image",{"name":"equal","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + " ></button> ";
},"2":function(container,depth0,helpers,partials,data) {
    return " opened";
},"4":function(container,depth0,helpers,partials,data) {
    return " aria-label=\"Toggle image preview\" ";
},"6":function(container,depth0,helpers,partials,data) {
    return " aria-label=\"Toggle website preview\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.preview : depth0), depth0),{"name":"preview","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});