var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return " <div id=\"chan-"
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" class=\"chan "
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" data-type=\""
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "\" role=\"tabpanel\" aria-label=\""
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "\"><div class=\"header\"><button class=\"lt\" aria-label=\"Toggle channel list\"></button> <span class=\"title\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</span><span title=\""
    + alias2(alias1((depth0 != null ? depth0.topic : depth0), depth0))
    + "\" class=\"topic\">"
    + ((stack1 = __default(require("../js/libs/handlebars/parse.js")).call(alias3,(depth0 != null ? depth0.topic : depth0),{"name":"parse","hash":{},"data":data})) != null ? stack1 : "")
    + "</span> <button class=\"menu\" aria-label=\"Open the context menu\"></button> "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias3,(depth0 != null ? depth0.type : depth0),"channel",{"name":"equal","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </div><div class=\"chat-content\"><div class=\"chat\"><div class=\"show-more"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.messages : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"><button class=\"btn\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" data-alt-text=\"Loading…\">Show older messages</button></div><div class=\"messages\" role=\"log\" aria-live=\"polite\" aria-relevant=\"additions\"></div></div> "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias3,(depth0 != null ? depth0.type : depth0),"channel",{"name":"equal","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </div></div> ";
},"2":function(container,depth0,helpers,partials,data) {
    return " <span class=\"rt-tooltip tooltipped tooltipped-w\" aria-label=\"Toggle user list\"><button class=\"rt\" aria-label=\"Toggle user list\"></button> </span> ";
},"4":function(container,depth0,helpers,partials,data) {
    return " show";
},"6":function(container,depth0,helpers,partials,data) {
    return " <aside class=\"userlist\"><div class=\"count\"><input type=\"search\" class=\"search\" aria-label=\"Search among the user list\" tabindex=\"-1\"></div><div class=\"names names-filtered\"></div><div class=\"names names-original\"></div></aside> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.channels : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});