var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression;

  return " <span role=\"button\" class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.original : depth0)) != null ? stack1.className : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.original : depth0)) != null ? stack1.dataset : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.string || (depth0 != null ? depth0.string : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"string","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"user-mode user-mode-search\"> "
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.matches : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </div>";
},"useData":true});