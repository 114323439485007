var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"date-marker-container tooltipped tooltipped-s\" data-time=\""
    + alias1(container.lambda((depth0 != null ? depth0.time : depth0), depth0))
    + "\" aria-label=\""
    + alias1(__default(require("../js/libs/handlebars/localedate.js")).call(alias2,(depth0 != null ? depth0.time : depth0),{"name":"localedate","hash":{},"data":data}))
    + "\"><div class=\"date-marker\"><span class=\"date-marker-text\" data-label=\""
    + alias1(__default(require("../js/libs/handlebars/friendlydate.js")).call(alias2,(depth0 != null ? depth0.time : depth0),{"name":"friendlydate","hash":{},"data":data}))
    + "\"></span></div></div>";
},"useData":true});