var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " <div class=\"toggle-content toggle-type-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.type : depth0), depth0))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.shown : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"> "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"image",{"name":"equal","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"audio",{"name":"equal","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"video",{"name":"equal","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"link",{"name":"equal","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"error",{"name":"equal","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </div> ";
},"2":function(container,depth0,helpers,partials,data) {
    return " show";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <a class=\"toggle-thumbnail\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\"><img src=\""
    + alias2(alias1((depth0 != null ? depth0.thumb : depth0), depth0))
    + "\" decoding=\"async\" alt=\"\"> </a> ";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <audio controls preload=\"metadata\"><source src=\""
    + alias2(alias1((depth0 != null ? depth0.media : depth0), depth0))
    + "\" type=\""
    + alias2(alias1((depth0 != null ? depth0.mediaType : depth0), depth0))
    + "\">Your browser does not support the audio element.</audio> ";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <video preload=\"metadata\" controls><source src=\""
    + alias2(alias1((depth0 != null ? depth0.media : depth0), depth0))
    + "\" type=\""
    + alias2(alias1((depth0 != null ? depth0.mediaType : depth0), depth0))
    + "\">Your browser does not support the video element.</video> ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return " "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.thumb : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " <div class=\"toggle-text\"><div class=\"head\"><div class=\"overflowable\"><a href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\" title=\""
    + alias2(alias1((depth0 != null ? depth0.head : depth0), depth0))
    + "\"> "
    + alias2(alias1((depth0 != null ? depth0.head : depth0), depth0))
    + " </a></div><button class=\"more\" aria-expanded=\"false\" aria-label=\"More\" data-closed-text=\"More\" data-opened-text=\"Less\"><span class=\"more-caret\"></span></button></div><div class=\"body overflowable\"><a href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\" title=\""
    + alias2(alias1((depth0 != null ? depth0.body : depth0), depth0))
    + "\"> "
    + alias2(alias1((depth0 != null ? depth0.body : depth0), depth0))
    + " </a></div></div> ";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <a class=\"toggle-thumbnail\" href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\"><img src=\""
    + alias2(alias1((depth0 != null ? depth0.thumb : depth0), depth0))
    + "\" decoding=\"async\" alt=\"\" class=\"thumb\"> </a> ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.error : depth0),"image-too-big",{"name":"equal","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/libs/handlebars/equal.js")).call(alias1,(depth0 != null ? depth0.error : depth0),"message",{"name":"equal","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return " <em>This image is larger than "
    + alias1(__default(require("../js/libs/handlebars/friendlysize.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.maxSize : depth0),{"name":"friendlysize","hash":{},"data":data}))
    + " and cannot be previewed. <a href=\""
    + alias1(container.lambda((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\">Click here</a> to open it in a new window. </em> ";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return " <div><em>A preview could not be loaded. <a href=\""
    + alias2(alias1((depth0 != null ? depth0.link : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener\">Click here</a> to open it in a new window.</em><br><pre class=\"prefetch-error\">"
    + alias2(alias1((depth0 != null ? depth0.message : depth0), depth0))
    + "</pre></div><button class=\"more\" aria-expanded=\"false\" aria-label=\"More\" data-closed-text=\"More\" data-opened-text=\"Less\"><span class=\"more-caret\"></span></button> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.preview : depth0), depth0),{"name":"preview","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});