var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " <tr><td class=\"hostmask\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.hostmask : depth0), depth0))
    + "</td><td class=\"when\">"
    + ((stack1 = __default(require("../../js/libs/handlebars/localetime.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.when : depth0),{"name":"localetime","hash":{},"data":data})) != null ? stack1 : "")
    + "</td></tr> ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<table class=\"ignore-list\"><thead><tr><th class=\"hostmask\">Hostmask</th><th class=\"when\">Ignored At</th></tr></thead><tbody> "
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ignored : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " </tbody></table>";
},"useData":true});